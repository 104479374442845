(function ($) {

	$(function () { });

	$(window).load(function () {

		var $_body = $('body'),
			$_window = $(this),
			currentScrollTop = 0;

		function init() {
			disableClick();
			init_daterangepicker_reservation();
		}
		init();

		function isIe() {
			var pattern = /Trident\/[0-9]+\.[0-9]+/;

			return pattern.test(navigator.userAgent);
		}

		function isEdge() {
			var pattern = /Edge\/[0-9]+\.[0-9]+/;

			return pattern.test(navigator.userAgent);
		}

		function disableClick() {
			$('.noclick').on('click', function () {
				return false;
			});
		}

		function scrollToPosition(pos, second) {
			$('html, body').animate({
				scrollTop: pos
			}, second * 1000);
		}


		function init_daterangepicker_reservation() {

			if (typeof ($.fn.daterangepicker) === 'undefined') { return; }
			console.log('init_daterangepicker_reservation');

			$('#range-reservation').daterangepicker(
				{
					ranges: {
						'오늘': [moment(), moment()],
						'어제': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
						'최근 7일': [moment().subtract(6, 'days'), moment()],
						'최근 30일': [moment().subtract(29, 'days'), moment()],
						'이번 달': [moment().startOf('month'), moment().endOf('month')],
						'지난 달': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
					},
					alwaysShowCalendars: true,
					locale: {
						format: 'YYYY.MM.DD',
						customRangeLabel: "맞춤 범위",
					}
				}, function (start, end, label) {
					console.log(start.toISOString(), end.toISOString(), label);
				});
		}

		$(".custom-file-input").on("change", function () {
			var fileName = $(this).val().split("\\").pop();
			$(this).siblings(".custom-file-label").addClass("selected").html(fileName);
		});

		// Good Trapey popup
		$('#model-gc-confirm').on('show.bs.modal', function () {
			$('#model-refund').modal('hide');
			$('#model-charge').modal('hide');
		});
		$('#model-charging, #model-refunding').on('show.bs.modal', function () {
			$('#model-gc-confirm').modal('hide');
		});

		$(window).resize(function () {

			// Resize

		});

		$('#date-search').datetimepicker({
			format: 'YYYY.MM.DD',
		});

		$('#delease-date').datetimepicker({
			format: 'YYYY.MM.DD',
		});

		$(".custom-file-input").on("change", function () {
			var fileName = $(this).val().split("\\").pop();
			$(this).siblings(".custom-file-label").addClass("selected").html(fileName);
		});
		$('#startDate').datetimepicker({
			format: 'YYYY.MM.DD',
			useCurrent: false,
		});

		$('#endDate').datetimepicker({
			format: 'YYYY.MM.DD',
			useCurrent: false,
		});
		$("#startDate").on("dp.change", function (e) {
			$('#endDate').data("DateTimePicker").minDate(e.date);
		});

		$('#date-start').datetimepicker({
			format: 'YYYY.MM.DD',
			useCurrent: false,
			minDate: moment()
		});

		$('#date-end').datetimepicker({
			format: 'YYYY.MM.DD',
			useCurrent: false,
			minDate: moment()
		});
		$("#date-start").on("dp.change", function (e) {
			var incrementDay = moment(new Date(e.date));
			incrementDay.add(1, 'days');
			$('#date-end').data('DateTimePicker').minDate(incrementDay);
			$(this).data("DateTimePicker").hide();
		});

		$('#date-end').datetimepicker().on('dp.change', function (e) {
			var decrementDay = moment(new Date(e.date));
			decrementDay.subtract(1, 'days');
			$('#date-start').data('DateTimePicker').maxDate(decrementDay);
			$(this).data("DateTimePicker").hide();
		});

		$('#start-preferential-revenue').datetimepicker({
			format: 'YYYY.MM.DD',
			useCurrent: false,
			minDate: moment()
		});

		$('#end-preferential-revenue').datetimepicker({
			format: 'YYYY.MM.DD',
			useCurrent: false,
			minDate: moment()
		});
		$("#start-preferential-revenue").on("dp.change", function (e) {
			var incrementDay = moment(new Date(e.date));
			incrementDay.add(1, 'days');
			$('#end-preferential-revenue').data('DateTimePicker').minDate(incrementDay);
			$(this).data("DateTimePicker").hide();
		});

		$('#end-preferential-revenue').datetimepicker().on('dp.change', function (e) {
			var decrementDay = moment(new Date(e.date));
			decrementDay.subtract(1, 'days');
			$('#start-preferential-revenue').data('DateTimePicker').maxDate(decrementDay);
			$(this).data("DateTimePicker").hide();
		});

		$('#modal-find-user').each(function () {
			const ps = new PerfectScrollbar('#modal-find-user .table tbody', {
				wheelSpeed: 2,
				wheelPropagation: true,
				minScrollbarLength: 20
			});
		});
	});
})(jQuery);